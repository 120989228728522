import React from 'react'
import { Helmet } from 'react-helmet'
import Swiper from 'react-id-swiper'
import Layout from '../components/Layout/index'
import './styles/responsabilidade-social.scss'
import { graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";

// assets
// import top from '../images/sustentabilidade/top.png'

// assets cards
import um from '../images/sustentabilidade/1.svg'
import dois from '../images/sustentabilidade/2.svg'
import tres from '../images/sustentabilidade/3.svg'
import quatro from '../images/sustentabilidade/4.svg'
import cinco from '../images/sustentabilidade/5.svg'
import seis from '../images/sustentabilidade/6.svg'
import sete from '../images/sustentabilidade/7.svg'
import oito from '../images/sustentabilidade/8.svg'
import nove from '../images/sustentabilidade/9.svg'

// dark mode
import umDM from '../images/sustentabilidade/1-DM.svg'
import doisDM from '../images/sustentabilidade/2-DM.svg'
import tresDM from '../images/sustentabilidade/3-DM.svg'
import quatroDM from '../images/sustentabilidade/4-DM.svg'
import cincoDM from '../images/sustentabilidade/5-DM.svg'
import seisDM from '../images/sustentabilidade/6-DM.svg'
import seteDM from '../images/sustentabilidade/7-DM.svg'
import oitoDM from '../images/sustentabilidade/8-DM.svg'
import noveDM from '../images/sustentabilidade/9-DM.svg'

// import diversidade from '../images/sustentabilidade/diversidade.png'

// assets meio ambiente
// import controleC02 from '../images/sustentabilidade/controle-de-co2.png'
// import energiaLimpa from '../images/sustentabilidade/energia-limpa.png'
// import gestaoResiduos from '../images/sustentabilidade/gestao-residuos.png'

// assets mesa

// import mesa1 from '../images/sustentabilidade/mesa-brasil1.png'
// import mesa2 from '../images/sustentabilidade/mesa-brasil2.png'
// import mesa3 from '../images/sustentabilidade/mesa-brasil3.png'
// import mesa4 from '../images/sustentabilidade/mesa-brasil4.png'
// import mesa5 from '../images/sustentabilidade/mesa-brasil5.png'
import logoMesa from '../images/sustentabilidade/logo-mesa-brasil.svg'

const swiperParams = {
  spaceBetween: 30,
  slidesPerView: 1.1,
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: true
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  },
  breakpoints: {
    550: {
      slidesPerView: 'auto',
      spaceBetween: 30
    },
    900: {
      slidesPerView: 'auto',
      spaceBetween: 30
    },
    1000: {
      slidesPerView: 'auto',
      spaceBetween: 30
    },
    1100: {
      slidesPerView: 'auto',
      spaceBetween: 30
    },
    1200: {
      slidesPerView: 3,
      spaceBetween: 30
    }
  }
}

const ResponsabilidadeCard = props => (
  <div className="bg-dia-gray dia-rounded">
    <div className="row justify-content-center">
      <img className="p-5 my-3 respon-icon-num lightMode" src={props.image} alt="Card de responsabilidades do DIA"/>
      <img className="p-5 my-3 respon-icon-num darkMode" src={props.darkMode} alt="Card de responsabilidades do DIA"/>
    </div>
    <div className="row justify-content-center">
      <div className="position-absolute h-100 d-flex align-items-center top-absolute-custom" >
        <p id='text-darkmode' className="text-center text-blue font-size-1 above m-4 m-lg-5">{props.text}</p>
      </div>
    </div>
  </div>
)

export default function Sustentabilidade (props) {
  const breadC = {
    visible: true,
    color: 'dark',
    items: [
      { path: '/', name: 'Home' },
      { path: '/responsabilidade-social/', name: 'Responsabilidade Social' }
    ]
  }

  return (
    <Layout breadCrumb={breadC}>
      <Helmet>
        <html lang="pt-BR" amp />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
        <title>Responsabilidade Social  &amp; Sustentabilidade - Dia Supermercados</title>
        <meta name="description" content="Eficácia, Iniciativa, Respeito, Equipe e Cliente são os valores que definem o DIA e se estabelecem como referência para a Ética Profissional e uma correta tomada de decisão." />
        <meta name="keywords" content="responsabilidade social, sustentabilidade" />
        <meta name="author" content="DIA Supermercados" />
      </Helmet>

      <section>
        <div className="bg-respon position-absolute bg-dia-gray"></div>
        <div className="container mb-5 pt-4 d-lg-block d-none">
          <div className="row justify-content-center">
            <div className="col-5 mt-5">
              <h1 className="text-red font-size-42px" tabIndex={0}>Responsabilidade Social <br/>Corporativa</h1>
              <h3 className="mt-5 pt-5 text-blue" id="politicas">Responsabilidade Social Corporativa</h3>
              <p className="mt-4 font-size-1125"><b>Eficácia, Iniciativa, Respeito, Equipe e Cliente</b> são os valores que definem o DIA e se estabelecem como referência para a Ética Profissional e uma correta tomada de decisão. Estes valores servem de fundamento para as práticas de Responsabilidade Social Corporativa, que são articuladas por meio de <b>nove princípios básicos</b>, sustentando o seu propósito de crescimento responsável e refletindo o seu compromisso com a sociedade e com o meio ambiente:</p>
            </div>
            <div className="col-5 mt-5 mb-5 p-0">
              <GatsbyImage
                image={props.data.imgTop.childImageSharp.gatsbyImageData}
                alt="varias pessoas colocando a mão no centro de uma roda de pessoas"
                className="dia-rounded" />
            </div>
          </div>
        </div>
        <div className="container mb-5 pt-5 d-lg-none d-block">
          <div className="row">
            <div className="col-12 mt-sm-5">
              <h2 className="text-red text-center" tabIndex={0}>Responsabilidade Social <br/>Corporativa</h2>
            </div>
            <div className="col-12 mt-5 mb-5 p-0 w-100 px-4">
              <GatsbyImage
                image={props.data.imgTop.childImageSharp.gatsbyImageData}
                alt="varias pessoas colocando a mão no centro de uma roda de pessoas"
                className="dia-rounded w-100" />
            </div>
            <div className="col-12 mx-md-4 p-4" id="politicas">
              <h3 className="mt-lg-5 pt-lg-5 text-blue">Política de Responsabilidade Social Corporativa</h3>
              <p className="mt-4 font-size-1125">Eficácia, Iniciativa, Respeito, Equipe e Cliente são os valores que definem o DIA e se estabelecem como referência para a Ética Profissional e uma correta tomada de decisão. Estes valores servem de fundamento para as práticas de Responsabilidade Social Corporativa, que são articuladas por meio de nove princípios básicos, sustentando o seu propósito de crescimento responsável e refletindo o seu compromisso com a sociedade e com o meio ambiente:</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container mb-5 p-0">
          <div id="swiperRespon">
            <Swiper {...swiperParams}>
              <div>
                <ResponsabilidadeCard image={um} darkMode={umDM} text={<> Promover as melhores práticas de Governança Corporativa, priorizando a transparência, a ética na gestão dos negócios e a gestão adequada dos riscos. </>}/>
              </div>

              <div>
                <ResponsabilidadeCard image={dois} darkMode={doisDM} text={<> Criar e manter uma comunicação responsável, clara e bidirecional com os grupos de interesse (consumidores, empregados, franqueados, fornecedores, sociedade civil e acionistas) para conhecer melhor as suas expectativas e adaptar o funcionamento do negócio de maneira eficiente à satisfação de todos os envolvidos. </>}/>
              </div>

              <div>
                <ResponsabilidadeCard image={tres} darkMode={tresDM} text={<> Trabalhar na melhoria contínua de todos os processos para favorecer o acesso a uma alimentação de qualidade com os melhores preços para o consumidor. </>}/>
              </div>

              <div>
                <ResponsabilidadeCard image={quatro} darkMode={quatroDM} text={<> Promover boas práticas de compra responsável e de alimentação saudável, bem como fornecer informação nutricional completa sobre os produtos em benefício do consumidor. </>}/>
              </div>

              <div>
                <ResponsabilidadeCard image={cinco} darkMode={cincoDM} text={<> Garantir a máxima proteção e privacidade dos consumidores e franqueados por meio de um cumprimento integral dos regulamentos existentes. </>}/>
              </div>

              <div>
                <ResponsabilidadeCard image={seis} darkMode={seisDM} text={<> Promover o respeito à diversidade, desenvolvendo condições adequadas para um ambiente de trabalho respeitoso e equalitário. </>}/>
              </div>

              <div>
                <ResponsabilidadeCard image={sete} darkMode={seteDM} text={<> Promover o cuidado da segurança e da saúde de todas as pessoas que integram a empresa. </>}/>
              </div>

              <div>
                <ResponsabilidadeCard image={oito} darkMode={oitoDM} text={<> Apoiar a formação e o desenvolvimento profissional das pessoas que fazem parte da empresa. </>}/>
              </div>

              <div>
                <ResponsabilidadeCard image={nove} darkMode={noveDM} text={<> Gerir de maneira eficiente os recursos naturais  de forma a minimizar os impactos negativos derivados da nossa atividade. </>}/>
              </div>

            </Swiper>
          </div>
        </div>
      </section>

      <section>

        <div className="container mb-5" id="pessoas/">
          <div className="row justify-content-center">
            <div className="col-lg-5 mr-lg-5 p-lg-0 d-flex justify-content-center px-4">
              <GatsbyImage
                image={props.data.imgDiversidade.childImageSharp.gatsbyImageData}
                alt="Pessoa em frente a um notebook e um copo de água do lado"
                className="dia-rounded img-fluid w-100" />
            </div>
            <div className="col-lg-5 text-left px-4 mt-4 mt-lg-0">
              <h3 className="text-red" tabIndex={0}>Pessoas</h3>
              <p className="font-size-1125 mt-4">A promoção do respeito acima de tudo está inserida em nossa cultura. O DIA promove ativamente a igualdade de oportunidades e repudia todo e qualquer tipo de discriminação entre os seus funcionários, seja por raça, idade, gênero, orientação sexual ou política, crenças religiosas, ou por qualquer outra circunstância.</p>
              <p className="font-size-1125 mt-4"><b>Todos nós temos as mesmas oportunidades</b> e somos valorizados com o mesmo critério nos processos de seleção, promoção ou formação, entre outros.
              </p>
            </div>
          </div>
        </div>

      </section>

      <section className="bg-dia-gray py-5" id="meioambiente/">
        <div className="container mb-5  px-lg-5">
          <div className="row">
            <div className="col text-center">
              <h2 className="text-blue mb-4" tabIndex={0}>Meio ambiente</h2>
              <p className="px-lg-5 font-size-1125">O DIA busca ser agente transformador da sociedade por meio da prática diária de atitudes sustentáveis, criando e incentivando ações e campanhas voltadas para <b>minimizar os impactos da sua operação no meio ambiente</b> e reforçando sempre a <b>importância da sustentabilidade</b> para a gestão estratégica da empresa, em que tudo possa ser ecologicamente correto e economicamente viável.</p>
            </div>
          </div>
        </div>

        <div className="container mb-5">
          <div className="row justify-content-center">
            <div className="col-lg-5 mr-lg-5 p-lg-0 d-flex justify-content-center px-4">
              <GatsbyImage
                image={props.data.imgControle.childImageSharp.gatsbyImageData}
                alt="um arbusto com a as letras CO2 cortadoas no interior dele"
                className="dia-rounded w-100" />
            </div>
            <div className="col-lg-5 text-left px-4 mt-4 mt-lg-0">
              <h3 className="text-blue" tabIndex={0}>Controle de CO2</h3>
              <p className="font-size-1125 mt-4">Pensando na <b>redução de emissão de poluentes que causam o efeito estufa</b>, o DIA conta com projeto de renovação da frota de caminhões. São instalados motores híbridos menos poluentes e com capacidade maior de carregamento, no qual é possível fazer menos viagens (reduzindo, dessa forma, o consumo de CO²), porém, sem prejudicar o número de entregas.</p>
            </div>
          </div>
        </div>

        <div className="container mb-2">
          <div className="row justify-content-center">
            <div className="col-lg-5 text-left text-lg-right px-4 p-lg-0 mt-4 mt-lg-0 order-12 order-lg-1">
              <h3 className="text-blue" tabIndex={0}>Gestão de Resíduos</h3>
              <p className="font-size-1125 mt-4">Por meio de logística reversa, <b>todos os resíduos gerados pelas Lojas e pelos Centros de Distribuição são separados de maneira eficiente.</b> Mais de 50% do volume dos resíduos produzidos recebem destinação ambientalmente adequada, evitando que sejam direcionados a aterros sanitários.</p>
              <p className="my-lg-4 font-size-1125">Com a doação de alimentos, além de contribuir com a redução da fome no país em que vivemos, reduzimos também os resíduos enviados para aterros sanitários.</p>
            </div>
            <div className="col-lg-5 ml-lg-5 p-lg-0 pl-lg-4 d-flex justify-content-center px-4 order-1 order-lg-12">
              <GatsbyImage
                image={props.data.imgResiduos.childImageSharp.gatsbyImageData}
                alt="varios resíduos em cima de uma mesa"
                className="dia-rounded w-100" />
            </div>
          </div>
        </div>
      </section>

      <section className="d-none d-lg-block bg-white" id="mesa/">
        <div className="container-fluid d-flex justify-content-center py-5 p-0 pr-lg-0 pl-lg-5">
          <div className="row ">
            <div className="col-lg-6 text-left mb-4 text-lg-right p-0 custom-max-width custom-margin-left pl-lg-4">
              <h2 className="text-green-1 mb-4" tabIndex={0}>Mesa Brasil</h2>
              <p className="mb-4 p-0  font-size-1125">Com o objetivo de combater o desperdício e contribuir para a segurança alimentar e nutricional de populações em risco social, o DIA Brasil transforma o alimento que está fora do padrão comercial, mas apto ao consumo, em solidariedade a mais de 415 mil pessoas, entre crianças, adolescentes e idosos que são beneficiados pelo Programa.</p>
              <p className="mb-4 p-0 font-size-1125">Por ano, são mais de 170 mil quilos de alimentos, e as doações são diversificadas (laticínios, industrializados, frutas e legumes, entre outros), ampliando, dessa forma, o cardápio nutricional das entidades participantes.</p>
              <p className="mb-4 p-0 font-size-1125">O Programa acontece por meio da parceria com o Mesa Brasil – SESC, em uma iniciativa que dissemina a cultura da responsabilidade compartilhada para a garantia do direito humano à alimentação. Nossas doações são realizadas na forma de “colheita urbana”, em que o SESC retira os produtos de nossos centros de distribuição na parte da manhã e à tarde realiza as entregas, conforme as necessidades das entidades. Com esse sistema, a utilização dos alimentos é mais rápida e não há perdas.</p>
              <a className="text-dark mb-4" href="http://www.sesc.com.br/portal/site/mesabrasilsesc/home/"><u>Veja a matéria sobre o programa no site do Mesa Brasil</u></a>
            </div>
            <div className="col-lg-6 px-2 pl-lg-5 ml-lg-3">
              <div className="d-flex mb-4 pb-md-2">
                <div className="ml-4 pl-2 mr-4">
                  <GatsbyImage
                    image={props.data.imgMesa1.childImageSharp.gatsbyImageData}
                    alt="entregador saindo do caminhão com varias caixas em um carrinho"
                    className="dia-rounded img-fluid mesa-1" />
                </div>
                <div className="d-flex align-items-end">
                  <GatsbyImage
                    image={props.data.imgMesa2.childImageSharp.gatsbyImageData}
                    alt="Pessoa com formulário e caneta nas mãos"
                    className="dia-rounded img-fluid mesa-2" />
                </div>
              </div>
              <div className="d-flex mb-4 pb-md-2 custom-max-height">
                <div className="mr-4 pr-2">
                  <GatsbyImage
                    image={props.data.imgMesa3.childImageSharp.gatsbyImageData}
                    alt="Pessoa conferindo etiquetas de produtos com maquininha"
                    className="dia-rounded img-fluid mesa-3" />
                </div>
                <div >
                  <GatsbyImage
                    image={props.data.imgMesa4.childImageSharp.gatsbyImageData}
                    alt="Pessoa descarregando caminhão de produtos"
                    className="dia-rounded img-fluid p-0 mesa-4" />
                </div>
              </div>
              <div className="d-flex  mb-4 pb-md-2 ml-md-4">
                <div className="ml-2 mr-4 pr-2">
                  <img alt="Logo do mesa brasil - Mesa Brasil no centro com um garfo e uma faca nos lados" className="img-fluid" src={logoMesa} width="170" height="137"/>
                </div>
                <div>
                  <GatsbyImage
                    image={props.data.imgMesa5.childImageSharp.gatsbyImageData}
                    alt="Pessoa assinando um formulário"
                    className="dia-rounded img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="d-block d-lg-none px-3 bg-white" id="mesabrasil/">
        <div className="container-fluid d-flex flex-column align-items-center justify-content-center py-5 px-2">
          <h2 className="text-green-1 mb-4" tabIndex={0}>Mesa Brasil</h2>
          <p className="mb-4 p-0 font-size-1125">Com o objetivo de <b>combater o desperdício e contribuir para a segurança alimentar e nutricional de populações em risco social</b>, o DIA Brasil transforma o alimento que está fora do padrão comercial, mas apto ao consumo, em solidariedade a mais de 415 mil pessoas, entre crianças, adolescentes e idosos que são beneficiados pelo Programa.</p>

          <div className="px-2 mb-5">
            <div className="d-flex mb-3 pb-md-2 ">
              <div className="ml-3 pl-2 mr-4 ">
                <GatsbyImage
                  image={props.data.imgMesa1.childImageSharp.gatsbyImageData}
                  alt="entregador saindo do caminhão com varias caixas em um carrinho"
                  className="dia-rounded img-fluid mesa-1" />
              </div>
              <div className="d-flex align-items-end">
                <GatsbyImage
                  image={props.data.imgMesa2.childImageSharp.gatsbyImageData}
                  alt="Pessoa com formulário e caneta nas mãos"
                  className="dia-rounded img-fluid mesa-2" />
              </div>
            </div>
            <div className="d-flex mb-3 pb-md-2">
              <div className="mr-3 pr-2">
                <GatsbyImage
                  image={props.data.imgMesa3.childImageSharp.gatsbyImageData}
                  alt="Pessoa conferindo etiquetas de produtos com maquininha"
                  className="dia-rounded img-fluid mesa-3" />
              </div>
              <div>
                <GatsbyImage
                  image={props.data.imgMesa4.childImageSharp.gatsbyImageData}
                  alt="Pessoa descarregando caminhão de produtos"
                  className="dia-rounded img-fluid p-0 mesa-4" />
              </div>
            </div>
            <div className="d-flex  mb-3 pb-md-2 ml-md-4">
              <div className="ml-2 mr-4 pr-2">
                <img alt="Logo do mesa brasil - Mesa Brasil no centro com um garfo e uma faca nos lados" className="img-fluid" src={logoMesa}/>
              </div>
              <div>
                <GatsbyImage
                  image={props.data.imgMesa5.childImageSharp.gatsbyImageData}
                  alt="Pessoa assinando um formulário"
                  className="dia-rounded img-fluid mesa-5" />
              </div>
            </div>
          </div>

          <p className="mb-4 p-0 font-size-1125">Por ano, <b>são mais de 170 mil quilos de alimentos</b>, e as doações são diversificadas (laticínios, industrializados, frutas e legumes, entre outros), ampliando, dessa forma, o cardápio nutricional das entidades participantes.</p>
          <p className="mb-4 p-0 font-size-1125">O Programa acontece por meio da parceria com o Mesa Brasil – SESC, em uma iniciativa que dissemina a cultura da responsabilidade compartilhada para a garantia do direito humano à alimentação. Nossas doações são realizadas na forma de “colheita urbana”, em que o SESC retira os produtos de nossos centros de distribuição na parte da manhã e à tarde realiza as entregas, conforme as necessidades das entidades. Com esse sistema, a utilização dos alimentos é mais rápida e não há perdas.</p>
          <a className="text-dark mb-4" href="http://www.sesc.com.br/portal/site/mesabrasilsesc/home/"><u>Veja a matéria sobre o programa no site do Mesa Brasil</u></a>
        </div>
      </section>

      <section className="bg-dia-gray py-5">
        <div className="container px-lg-5">
          <div className="row">
            <div className="col text-center">
              <h2 className="text-blue mb-4" tabIndex={0}>Práticas Sustentáveis</h2>
              <h3 className="text-blue">Ainda mais Perto de um DIA Melhor e um Futuro Mais Sustentável</h3>
              <p className="px-lg-5 font-size-1125 mb-4">
                Em 2021, o Grupo DIA criou um Plano de Sustentabilidade, alinhado a dez Objetivos de Desenvolvimento Sustentável definidos pela Organização das Nações Unidas (ONU).
              </p>
              <p className="px-lg-5 font-size-1125">
                Focado em aspectos sociais, ambientais, éticos e de boa governança, o Plano de Sustentabilidade é dividido em 15 projetos com iniciativas voltadas à Diversidade, Gestão de Resíduos, Saúde & Segurança, Apoio à Comunidade, Mudanças Climáticas, Segurança Alimentar, dentre outros.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container mb-5 px-lg-5">
          <div className='row mt-5'>
            <div className='col-12 col-lg-4'>
              <GatsbyImage
                image={props.data.galinha.childImageSharp.gatsbyImageData}
                alt="Bem esta Animal"
                className='w-100' />
            </div>
            <div className='col-12 col-lg-8 pr-lg-5'>
              <h3 className="text-blue" tabIndex={0}>Bem-Estar Animal</h3>
              <p className='mb-4 font-size-1125'>
                Como parte da estratégia global de sustentabilidade definida pelo Grupo DIA, o DIA Brasil assume o compromisso de garantir que, até 2028, 100% dos ovos comercializados em todas as suas lojas sejam provenientes de galinhas livres de confinamento em gaiolas.
              </p>
              <p className='mb-4 font-size-1125'>
                O bem-estar animal é uma das iniciativas do Plano de Sustentabilidade, que tem como foco ações sociais, ambientais e éticos. Tais ações vão ao encontro do propósito da empresa "Cada DIA Mais Perto" e contribuirão para um DIA melhor e, sobretudo, para um futuro mais sustentável.
              </p>
              <p className='font-size-1125'>
                São Paulo, 08 de dezembro de 2021.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-dia-gray py-5">
        <div className="container px-lg-5">
          <div className='row justify-content-center'>
            <div className='col-12 col-lg-6 pr-lg-5'>
              <p className='mb-4 font-size-1125'>O Dia Brasil anuncia a ampliação de seus compromissos em relação ao bem-estar animal, uma das iniciativas de seu Plano de Sustentabilidade Global, que tem como foco ações sociais, ambientais e de governança (ESG).</p>
              <p className='mb-4 font-size-1125'>
                Diante disso, assumimos o compromisso de comercializar, em todas as nossas operações localizadas no Brasil, produtos que contenham carne suína proveniente de produção que contemple o bem-estar animal, de acordo com as seguintes diretrizes e datas:
              </p>
            </div>
            <div className='col-12 col-lg-5'>
              <GatsbyImage
                image={props.data.porcos.childImageSharp.gatsbyImageData}
                alt="Bem esta Animal"
                className='w-100 dia-rounded' />
            </div>
            <div className='col-11 mt-lg-2 mt-4'>
              <ul className='bulletsList font-size-1125 text-dark mb-4'>
                <li>Até dezembro 2028: comercializar 100% dos produtos que contenham carne suína somente de sistemas com porcas livres de celas de gestação, em alojamento coletivo;</li>
                <li>Até dezembro de 2023: adquirir produtos de fornecedores (mínimo 50%) que não realizem corte ou redução de dentes;</li>
                <li>Até dezembro de 2023: adquirir produtos de fornecedores (mínimo 50%)  que não realizem corte de cauda;</li>
                <li>Até dezembro de 2023: adquirir produtos de fornecedores  (mínimo 50%) que utilizem imunocastração.</li>
              </ul>
              <p className='font-size-1125 mb-4'>
                O processo de transição será reportado anualmente, seguindo o propósito "Cada DIA Mais Perto" e contribuindo para um Dia melhor e, sobretudo, para um futuro mais sustentável.
              </p>
              <p className='font-size-1125'>
                São Paulo, 15 de março de 2023.
              </p>
            </div>
          </div>
        </div>
      </section>
      <hr className='m-0' style={{ borderTop: '3px solid white' }} />

    </Layout>
  )
}

export const query = graphql`{
  imgTop: file(relativePath: {eq: "sustentabilidade/top.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 470, height: 470, layout: CONSTRAINED)
    }
  }
  imgDiversidade: file(relativePath: {eq: "sustentabilidade/diversidade.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 470, height: 470, layout: CONSTRAINED)
    }
  }
  imgControle: file(relativePath: {eq: "sustentabilidade/controle-de-co2.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 450, height: 280, layout: CONSTRAINED)
    }
  }
  imgResiduos: file(relativePath: {eq: "sustentabilidade/gestao-residuos.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 450, height: 340, layout: CONSTRAINED)
    }
  }
  imgMesa1: file(relativePath: {eq: "sustentabilidade/mesa-brasil1.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 240, height: 240, layout: FIXED)
    }
  }
  imgMesa2: file(relativePath: {eq: "sustentabilidade/mesa-brasil2.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 200, height: 200, layout: FIXED)
    }
  }
  imgMesa3: file(relativePath: {eq: "sustentabilidade/mesa-brasil3.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 200, height: 270, layout: FIXED)
    }
  }
  imgMesa4: file(relativePath: {eq: "sustentabilidade/mesa-brasil4.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 340, height: 270, layout: FIXED)
    }
  }
  imgMesa5: file(relativePath: {eq: "sustentabilidade/mesa-brasil5.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 240, height: 240, layout: FIXED)
    }
  }
  galinha: file(relativePath: {eq: "sustentabilidade/galinha.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 495, height: 490, layout: CONSTRAINED)
    }
  }
  porcos: file(relativePath: {eq: "sustentabilidade/pigs.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
}
`
